import React, { Component } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import request from '../../utils/Request';
import { navigate } from 'gatsby';
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker
} from 'react-google-maps';
import { Grid, Row, Col, Button, Modal } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import ImageGallery from 'react-image-gallery';

const ListingDetailsPage = props => {
  const url = props.location.pathname;
  const listingId = url.split('/').pop();
  return <ListingDetail listingId={listingId} />;
};

class ListingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listing: {},
      property: {},
      images: [],
      galleryImages: [],
      mainImage: '',
      show: false,
      featuresCount: null,
      offset: false
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.loadProperties();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  checkMarkSwitch(feature) {
    if (feature === true) {
      return 'check';
    } else {
      return 'times';
    }
  }

  changeColor(feature) {
    if (feature === true) {
      return 'green';
    } else {
      return 'red';
    }
  }

  handleClose() {
    this.setState({ show: !this.state.show });
  }

  changeMainImage(path) {
    if (path === null) return;
    this.setState({
      mainImage: path
    });
  }

  setGalleryImages(images) {
    const api = process.env.GATSBY_API_URL;
    const imgArray = images.map(image => {
      return {
        original: api + image.originalPath,
        thumbnail: api + image.originalPath
      };
    });
    return imgArray;
  }

  setDisplayImage(images) {
    const api = process.env.GATSBY_API_URL;

    if (this.state.images.length === 2) {
      return (
        <div className="container-fluid" style={{ paddingBottom: '10px' }}>
          <Col xs={6} style={{ padding: 'unset' }}>
            <span className="thumb-info" onClick={this.handleClose}>
              <span className="thumb-info-wrapper">
                <img
                  src={`${api}${images[0].originalPath}`}
                  className="listing-image"
                  alt="img"
                />
              </span>
            </span>
          </Col>
          <Col xs={6} style={{ padding: 'unset', paddingLeft: '5px' }}>
            <span className="thumb-info" onClick={this.handleClose}>
              <span className="thumb-info-wrapper">
                <img
                  src={`${api}${images[1].originalPath}`}
                  className="listing-image"
                  alt="img"
                />
              </span>
            </span>
          </Col>
        </div>
      );
    } else if (this.state.images.length === 1) {
      return (
        <Col xs={12}>
          <span className="thumb-info" onClick={this.handleClose}>
            <span className="thumb-info-wrapper">
              <img
                src={`${api}${images[0].originalPath}`}
                className="listing-image"
                alt="img"
              />
            </span>
          </span>
        </Col>
      );
    } else if (this.state.images.length === 3) {
      return (
        <div className="container-fluid" style={{ paddingBottom: '10px' }}>
          <Col xs={8} style={{ padding: 'unset' }}>
            <span className="thumb-info" onClick={this.handleClose}>
              <span className="thumb-info-wrapper">
                <img
                  src={`${api}${images[0].originalPath}`}
                  className="listing-image"
                  alt="img"
                />
              </span>
            </span>
          </Col>
          <Col xs={4} style={{ padding: 'unset', paddingLeft: '5px' }}>
            <Col xs={12} style={{ padding: 'unset', marginBottom: '5px' }}>
              <span className="thumb-info" onClick={this.handleClose}>
                <span className="thumb-info-wrapper">
                  <img
                    src={`${api}${images[1].originalPath}`}
                    className="listing-small-image-1"
                    alt="img"
                  />
                </span>
              </span>
            </Col>
            <Col xs={12} style={{ padding: 'unset' }}>
              <span className="thumb-info" onClick={this.handleClose}>
                <span className="thumb-info-wrapper">
                  <img
                    src={`${api}${images[2].originalPath}`}
                    className="listing-small-image-2"
                    alt="img"
                  />
                </span>
              </span>
            </Col>
          </Col>
        </div>
      );
    } else {
      return (
        <div className="container-fluid" style={{ paddingBottom: '10px' }}>
          <Col xs={8} style={{ padding: 'unset' }}>
            <span className="thumb-info" onClick={this.handleClose}>
              <span className="thumb-info-wrapper">
                <img
                  src={`${api}${images[0].originalPath}`}
                  className="listing-image"
                  alt="img"
                />
              </span>
            </span>
          </Col>
          <Col xs={4} style={{ padding: 'unset', paddingLeft: '5px' }}>
            <Col xs={12} style={{ padding: 'unset', marginBottom: '5px' }}>
              <span className="thumb-info" onClick={this.handleClose}>
                <span className="thumb-info-wrapper">
                  <img
                    src={`${api}${images[1].originalPath}`}
                    className="listing-small-image-1"
                    alt="img"
                  />
                </span>
              </span>
            </Col>
            <Col xs={12} style={{ padding: 'unset' }}>
              <span className="thumb-info" onClick={this.handleClose}>
                <span className="thumb-info-wrapper">
                  <img
                    src={`${api}${images[2].originalPath}`}
                    className="listing-small-image-2"
                    alt="img"
                  />
                  <span className="listing-image-tag">
                    +{images.length} photos
                  </span>
                </span>
              </span>
            </Col>
          </Col>
        </div>
      );
    }
  }

  getFeatureCount(property) {
    let count = 0;

    if (property.airConditioning === true) {
      count++;
    }
    if (property.homeTheater === true) {
      console.log(1);
      count++;
    }
    if (property.centralHeating === true) {
      console.log(2);
      count++;
    }
    if (property.laundry === true) {
      console.log(4);
      count++;
    }
    if (property.balcony === true) {
      console.log(3);
      count++;
    }
    if (property.storage === true) {
      count++;
    }
    if (property.garage === true) {
      count++;
    }
    if (property.yard === true) {
      count++;
    }
    if (property.electricWaterHeater === true) {
      count++;
    }
    if (property.deck === true) {
      count++;
    }
    if (property.gym === true) {
      count++;
    }
    if (property.oceanView === true) {
      count++;
    }
    console.log(count);
    return count;
  }

  loadProperties() {
    request
      .get('/propertylisting/' + this.props.listingId)
      .then(listing => {
        if (listing.images[0] !== undefined) {
          this.setState({ images: listing.images });
          this.setState({ mainImage: this.setDisplayImage(listing.images) });
          this.setState({
            galleryImages: this.setGalleryImages(listing.images)
          });
          this.setState({ listing: listing });
          this.setState({ property: listing.property });
          this.setState({
            featuresCount: this.getFeatureCount(listing.property)
          });
        } else {
          navigate('/listings/');
        }
      })
      .catch(err => {
        navigate('/listings/');
      });
  }
  
  rawMarkup() {
    var rawMarkup = this.state.listing.text;
    return { __html: rawMarkup };
  }

  displayFeature(feature, text) {
    console.log(this.state.featuresCount);
    if (feature === true && this.state.featuresCount === 1) {
      return (
        <li className="col-xs-12">
          <FaCheck className="fa" style={{ color: '#337ab7' }} />
          {text}
        </li>
      );
    } else if (feature === true && this.state.featuresCount === 2) {
      return (
        <li className="col-xs-6">
          <FaCheck className="fa" style={{ color: '#337ab7' }} />
          {text}
        </li>
      );
    } else if (feature === true && this.state.featuresCount === 3) {
      return (
        <li className="col-xs-4">
          <FaCheck className="fa" style={{ color: '#337ab7' }} />
          {text}
        </li>
      );
    } else if (feature === true && this.state.featuresCount === 4) {
      return (
        <li className="col-xs-3">
          <FaCheck className="fa" style={{ color: '#337ab7' }} />
          {text}
        </li>
      );
    } else if (feature === true && this.state.featuresCount === 5) {
      return (
        <li className="col-md-5ths">
          <FaCheck className="fa" style={{ color: '#337ab7' }} />
          {text}
        </li>
      );
    } else if (feature === true && this.state.featuresCount <= 6) {
      return (
        <li className="col-xs-2">
          <FaCheck className="fa" style={{ color: '#337ab7' }} />
          {text}
        </li>
      );
    } else {
      // Client wanted features that aren't present to not be shown at all - this line would show them with red X's
      // return (<li className="col-sm-6 col-md-4"><FaTimes className="fa" style={{color: 'red'}}/>{text}</li>)

      //Just return null instead to hide those features
      return null;
    }
  }

  render() {
    const api = process.env.GATSBY_API_URL;
    const ListingMapComponent = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultZoom={14}
          defaultCenter={{
            lat: this.state.property.latitude,
            lng: this.state.property.longitude
          }}>
          {props.isMarkerShown && (
            <Marker
              position={{
                lat: this.state.property.latitude,
                lng: this.state.property.longitude
              }}
            />
          )}
        </GoogleMap>
      ))
    );
    return (
      <Layout>
        <SEO
          title="Listing Details | Korecki Real Estate Services"
          description="Find Properties for Rent through us. We provide professional, experienced Strata Management services to clients throughout the Lower Mainland."
          keywords="korecki,Real Estate,Strata Management services, Rent, Properties, Property Management , Client Property Account System, Listings, Property Listings"
        />
        <section className="page-header page-header-light page-header-more-padding">
          <Grid>
            <Row md={12}>
              <h1>
                {this.state.property.street}
                <span>{this.state.property.city}</span>
                <span>{this.state.listing.available ? "" : "* Rented *"}</span>
              </h1>
            </Row>
          </Grid>
        </section>
        <Grid>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={7}>
                  <Row>{this.state.mainImage}</Row>
                </Col>
                <Col md={5}>
                  <Row>
                    <table className="table table-striped">
                      <colgroup>
                        <col width="35%" />
                        <col width="65%" />
                      </colgroup>
                      <tbody>
                        <tr className="background-color-listing-price">
                          <td className="text-light pt-md">Price</td>
                          <td className="font-size-xl font-weight-bold pt-sm pb-sm text-light">
                            ${this.state.listing.price}
                          </td>
                        </tr>
                        <tr>
                          <td>Listing ID</td>
                          <td>#{this.state.listing.id}</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            {this.state.property.street} -{' '}
                            {this.state.property.city} -{' '}
                            {this.state.property.province}
                            <br />
                            <a
                              href="#mapContainer"
                              className="font-size-sm"
                              data-hash
                              data-hash-offset="100">
                              (Map Location)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Beds</td>
                          <td>{this.state.property.bedroom}</td>
                        </tr>
                        <tr>
                          <td>Baths</td>
                          <td>{this.state.property.bathroom}</td>
                        </tr>
                        <tr>
                          <td>Living Area</td>
                          <td>{this.state.property.sqft} sqft</td>
                        </tr>
                        {this.state.property.parkingAvailable && (
                          <tr>
                            <td>Parking Available</td>
                            <td>Yes</td>
                          </tr>
                        )}
                        {this.state.property.den && (
                          <tr>
                            <td>+ Den</td>
                            <td>Yes</td>
                          </tr>
                        )}
                        {this.state.property.halfWashroom && (
                          <tr>
                            <td>+ Half washroom</td>
                            <td>Yes</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <h4>Features</h4>
                  <ul
                    className="list list-icons list-secondary row m-none"
                    style={{ display: 'contents' }}>
                    {this.displayFeature(
                      this.state.property.airConditioning,
                      'Air Conditioning'
                    )}
                    {this.displayFeature(
                      this.state.property.homeTheater,
                      'Home Theater'
                    )}
                    {this.displayFeature(
                      this.state.property.centralHeating,
                      'Central Heating'
                    )}
                    {this.displayFeature(
                      this.state.property.laundry,
                      'Laundry'
                    )}
                    {this.displayFeature(
                      this.state.property.balcony,
                      'Balcony'
                    )}
                    {this.displayFeature(
                      this.state.property.storage,
                      'Storage'
                    )}
                    {this.displayFeature(this.state.property.garage, 'Garage')}
                    {this.displayFeature(this.state.property.yard, 'Yard')}
                    {this.displayFeature(
                      this.state.property.electricWaterHeater,
                      'Electric Water Heater'
                    )}
                    {this.displayFeature(this.state.property.deck, 'Deck')}
                    {this.displayFeature(this.state.property.gym, 'Gym')}
                    {this.displayFeature(
                      this.state.property.oceanView,
                      'Ocean View'
                    )}
                  </ul>
                </Col>
              </Row>
              <Row>
                <hr className="solid tall" />
                <Col md={12}>
                  <h4>{this.state.listing.description}</h4>
                  <p
                    style={{ whiteSpace: 'pre-wrap' }}
                    dangerouslySetInnerHTML={this.rawMarkup()}
                  />
                  <hr className="solid tall" />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <h4>Map Location</h4>
                  <ListingMapComponent
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCw3rCEWdJhxVIHDVytP65mqsSU08vDTqU&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={
                      <div style={{ height: `400px`, marginBottom: `10%` }} />
                    }
                    mapElement={<div style={{ height: `100%` }} />}
                    id="mapContainer"
                  />
                </Col>
              </Row>
            </Col>
            <Col md={3}></Col>
          </Row>
        </Grid>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Gallery</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ImageGallery
              showPlayButton={false}
              items={this.state.galleryImages}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    );
  }
}

export default ListingDetailsPage;
