/**
 * Created by robertclifford on 2017-11-29.
 */
import 'whatwg-fetch'
import Auth from './Auth'

const api = process.env.GATSBY_API_URL ? process.env.GATSBY_API_URL + '/api' : 'https://portal.korecki.ca/api'
//const api = 'http://korecki.cldevbox.com/api'
let headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

function parseJSON (response) {
  return response.json()
}

function checkStatus (response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    var error = new Error(response.statusText)
    error.response = response
    throw error
  }
}
const Request = {
  /**
   * Make GET request and parse data
   * @param endpoint
   * @returns {Promise.<TResult>}
   */
  get (endpoint) {
    return fetch(api + endpoint, {
      method: 'get',
      headers: Auth.setAuthHeader(headers)
    })
    .then(checkStatus)
    .then(parseJSON)
    .then(res => {
      return res
    })
    .catch(err => {
      // return err
      return Promise.reject('Error: ' +  err)

    })
  },

  /**
   * Post data to api endpoint
   * @param endpoint
   * @param data
   * @returns {Promise.<TResult>}
   */
  post (endpoint, data) {
    return fetch(api + endpoint, {
      method: 'post',
      headers: Auth.setAuthHeader(headers),
      body: JSON.stringify(data)
    })
    .then(checkStatus)
    .then(parseJSON)
    .then(data => {
      return data
    })
    .catch(err => {
      return Promise.reject('Error: ' +  err)
      // return err
    })
  },


  /**
   * Put data to api endpoint
   * @param endpoint
   * @param data
   * @returns {Promise.<TResult>}
   */
  put (endpoint, data) {
    return fetch(api + endpoint, {
      method: 'put',
      headers: Auth.setAuthHeader(headers),
      body: JSON.stringify(data)
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(data => {
        return data
      })
      .catch(err => {
        return Promise.reject('Error: ' +  err)
        // return err
      })
  },

  /**
   * Delete request
   * @param endpoint
   * @param data
   * @returns {Promise.<TResult>}
   */
  delete (endpoint, data) {
    return fetch(api + endpoint, {
      method: 'delete',
      headers: Auth.setAuthHeader(headers),
      body: JSON.stringify(data)
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(data => {
        return data
      })
      .catch(err => {
        return err
      })
  },

  /**
  * Send Strata Request Form request
  * @param endpoint
  * @param data
  * @returns {Promise<TResult>}
  */
  sendStrataRequest (endpoint, data) {
    return fetch(api + endpoint, {
      method: 'post',
      headers: Auth.setAuthHeader(headers),
      body: JSON.stringify(data)
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(data => {
        return data
      })
      .catch(err => {
        return Promise.reject('Email Not Found')
        // return err
      })
  },

  /**
   *
   * @param endpoint
   * @param files
   * @returns {Promise<Response>}
   */
  upload (endpoint, file, sortOrder) {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('sortOrder', sortOrder)

    return fetch(api + endpoint, {
        method: 'post',
        body: formData
      })
      .then(checkStatus)
      // .then(parseJSON)
      .then(data => {
        return data
      })
      .catch(err => {
        return Promise.reject('Request failed', err)
        // return err
      })
  },

    /**
     * Second Upload function that does put
     * @param endpoint
     * @param files
     * @returns {Promise<Response>}
     */
    upload2 (endpoint, file) {
        const formData = new FormData();
        formData.append('file', file)
        return fetch(api + endpoint, {
            method: 'put',
            body: formData,
        })
            .then(checkStatus)
            .then(parseJSON)
            .then(data => {
                return data
            })
            .catch(err => {
                return Promise.reject('Request failed', err)
                // return err
            })
    },

  /**
   * Log user in and store token
   * @param data
   * @returns {Promise.<TResult>}
   */
  login (data) {
    return fetch(api + `/auth?userName=${data.userName}&password=${data.password}`, {
      method: 'post',
      headers: Auth.setAuthHeader(headers),
      body: JSON.stringify(data)
    })
    .then(checkStatus)
    .then(parseJSON)
    .then(data => {
      if (data.token) {
        localStorage.token = data.token;
        localStorage.user_id = data.user.id
        localStorage.role = data.user.role
        localStorage.email = data.user.email
        localStorage.name = data.user.name
      }
      return data
    })
    .catch(err => {
      return Promise.reject('Request failed')
    })
  },


    /**
    * Archive
    * @param endpoint
    * @param data
    * @returns {Promise<TResult>}
    */
    archive(endpoint) {
      return fetch(api + endpoint, {
        method: 'put',
        headers: Auth.setAuthHeader(headers),
      })
        .then(checkStatus)
        .then(parseJSON)
        .then(data => {
          return data
        })
        .catch(err => {
          return Promise.reject('Unable to archive')
          // return err
        })
    },

    /**
     * @param endpoint
     * @returns {Promise.<TResult>}
     */
    getIDByAddress (endpoint, data) {
      return fetch(api + endpoint, {
        method: 'post',
        headers: Auth.setAuthHeader(headers),
        body: JSON.stringify(data)
      })
      .then(checkStatus)
      .then(parseJSON)
      .then(res => {
        return res
      })
      .catch(err => {
        return Promise.reject('Request failed: ' + err)
      })
    },

    /**
     * @param endpoint
     * @returns {Promise.<TResult>}
     */
    getOwnerIDByName (endpoint) {
      return fetch(api + endpoint, {
        method: 'post',
        headers: Auth.setAuthHeader(headers),

      })
      .then(checkStatus)
      .then(parseJSON)
      .then(res => {
        return res
      })
      .catch(err => {
        return Promise.reject('Request failed: ' + err)
      })
    },
  }

export default Request
