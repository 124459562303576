/**
 * Created by robertclifford on 2017-12-10.
 */
//import { createContext, useContext } from 'react';

const Auth = {

  isAuthenticated () {
    return !! localStorage.token
  },

  setAuthHeader (headers) {
    if (localStorage.token) {
      headers.Authorization = 'Bearer ' + localStorage.token
    }
    return headers
  }
}

export default Auth
